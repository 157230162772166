<template>
    <v-container fluid>
        <v-row>
            <v-col md="8" offset-md="2">
                <v-card class="mb-3">
                    <v-card-text class="text-end">
                        <v-btn icon @click="print">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-card>
                <div id="reportContent">
                    <v-card class="d-flex flex-column align-center">
                        <v-card-title style="padding: 5px;font-size: 25px;font-weight: 700;">
                            From 9
                        </v-card-title>
                        <v-card-text>
                            <h2 class="m-0 text-center font-weight-black">
                                {{company_profile.name}}
                            </h2>
                            <p class="m-0 text-center font-weight-black">
                                <span style="font-size: 10px;">{{company_profile.address}}</span>
                                <br>
                                <span style="font-size: 10px;">
                                    Email:{{company_profile.email}}, Contact:{{company_profile.phone}}
                                </span>
                            </p>
                            <v-simple-table class="custom-simple-table">
                                <template>
                                    <thead>
                                        <tr>
                                            <th>
                                                <h2 class="m-0 text-center font-weight-black">Leave Application</h2>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="padding: 15px;">
                                                <v-row>
                                                    <v-col cols="6" class="d-flex" style="padding: 4px 14px;">
                                                        <h5 class="m-0 me-1 font-weight-black">Name:</h5>
                                                        <span style="font-size: 12px;" v-text="leave.employee.name"></span>
                                                    </v-col>
                                                    <v-col cols="6" class="d-flex justify-end"
                                                        style="padding: 4px 14px;">
                                                        <h5 class="m-0 me-1 font-weight-black">Date:</h5>
                                                        <span style="font-size: 12px;">{{ today }}</span>
                                                    </v-col>
                                                    <v-col cols="6" class="d-flex" style="padding: 4px 14px;">
                                                        <h5 class="m-0 me-1 font-weight-black">Designation:</h5>
                                                        <span style="font-size: 12px;" v-text="leave.employee.designation.name"></span>
                                                    </v-col>
                                                    <v-col cols="6" class="d-flex justify-end"
                                                        style="padding: 4px 14px;">
                                                        <h5 class="m-0 me-1 font-weight-black">Department:</h5>
                                                        <span style="font-size: 12px;" v-text="leave.employee.department.name"></span>
                                                    </v-col>
                                                    <v-col cols="6" class="d-flex"
                                                        style="padding: 4px 14px;">
                                                        <h5 class="m-0 me-1 font-weight-black">Join Date:</h5>
                                                        <span style="font-size: 12px;" v-text="leave.employee.joining_date"></span>
                                                    </v-col>
                                                </v-row>
                                                <v-simple-table class="custom-simple-table mt-4">
                                                    <template>
                                                        <thead>
                                                            <tr>
                                                                <td class="text-center font-weight-black"
                                                                    style="font-size:9px;font-weight: 900;">Leave Infromation
                                                                </td>
                                                                <td class="text-center font-weight-black"
                                                                    style="font-size:9px;font-weight: 900;">Available Leave
                                                                </td>
                                                                <td class="text-center font-weight-black"
                                                                    style="font-size:9px;font-weight: 900;">Date From</td>
                                                                <td class="text-center font-weight-black"
                                                                    style="font-size:9px;font-weight: 900;">Date To</td>
                                                                <td class="text-center font-weight-black"
                                                                    style="font-size:9px;font-weight: 900;">Rest of The Leave
                                                                </td>
                                                                <td class="text-center font-weight-black"
                                                                    style="font-size:9px;font-weight: 900;">Total Days</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td style="font-size: 11px;" class="text-center font-weight-black" v-text="leave.leave_type.name"></td>
                                                                <td style="font-size: 11px;" class="text-center font-weight-black" v-text="leave.previous_leave"></td>
                                                                <td style="font-size: 11px;" class="text-center font-weight-black" v-text="dateFrom"></td>
                                                                <td style="font-size: 11px;" class="text-center font-weight-black" v-text="dateTo"> </td>
                                                                <td style="font-size: 11px;" class="text-center font-weight-black" v-text="leave.previous_leave - leave.total_leave"></td>
                                                                <td style="font-size: 11px;" class="text-center font-weight-black" v-text="leave.total_leave"></td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>

                                                <v-row class="mt-6">
                                                    <v-col cols="3 text-center font-weight-black">
                                                        <span style="font-size: 10px;font-weight: 600;border-top: 2px solid gray;">Officer Signature</span>
                                                    </v-col>
                                                    <v-col cols="4 text-center font-weight-black">
                                                        <span style="font-size: 10px;font-weight: 600;border-top: 2px solid gray;">Manager Signature</span>
                                                    </v-col>
                                                    <v-col cols="5 text-center font-weight-black">
                                                        <span style="font-size: 10px;font-weight: 600;border-top: 2px solid gray;">Managing Director Signature</span>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';
export default {
    name: "LeaveInvoice",

    data() {
        return {
            leave: {
                employee: {
                    department:{},
                    designation:{}
                },
                leave_dates:[],
                leave_type:{}
            },
            dateFrom: '',
            dateTo: '',
            today: moment().format('DD/MM/YYYY'),
            company_profile: {},
            loading: false,
        };
    },

    created() {
        this.getLeave();
        this.company_profile = this.$store.getters['company/company'];
    },

    methods: {
        getLeave(){
            axios.post('/get-leaves', {id: this.$route.params.id})
            .then(res => {
                this.leave = res.data[0];
                this.dateFrom = moment(this.leave.leave_dates[0].leave_date).format("DD/MM/YYYY");
                this.dateTo = moment(this.leave.leave_dates[this.leave.leave_dates.length - 1].leave_date).format("DD/MM/YYYY");
            })
        },
        async print() {
            let div = document.getElementById('reportContent').innerHTML;
            const oldTitle = window.document.title;
            window.document.title = "Leave Invoice"
            const printWindow = document.createElement('iframe');
            document.body.appendChild(printWindow);
            printWindow.srcdoc = `
                    <style>
                        @import url('https://cdn.jsdelivr.net/npm/vuetify@2.6.4/dist/vuetify.min.css');

                        .custom-simple-table table {
                            border-collapse: collapse;
                            width: 100%;
                        }
                        
                        .custom-simple-table th,
                        .custom-simple-table td {
                            border: 1px solid #808080;
                            padding: 8px;
                            text-align: left;
                        }
                        .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                            border-bottom: thin solid rgb(0 0 0 / 61%);
                        }
                    </style>
                    <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                        <div class="container container--fluid">
                            <div class="row">
                                <div class="col-12">
                                    ${document.getElementById('reportContent').innerHTML}
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            printWindow.onload = async function () {
                printWindow.contentWindow.focus();
                await new Promise(resolve => setTimeout(resolve, 500));
                printWindow.contentWindow.print();
                document.body.removeChild(printWindow);
                window.document.title = oldTitle;
            };
        }
    },
};
</script>

<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: thin solid rgb(0 0 0 / 61%);
}

iframe {
    display: none;
}

.custom-simple-table table {
    border-collapse: collapse;
    width: 100%;
}

.custom-simple-table th,
.custom-simple-table td {
    border: 1px solid #808080;
    padding: 8px;
    text-align: left;
}
</style>
